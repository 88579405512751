import { DeleteRounded } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Typography, Switch, FormControlLabel } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { IList, IListItem } from '../../../schemas/interfaces';

const ItemDeleteDialog: FC<{
    onMenuItemClick?: () => void;
    handleItemDelete?: () => void;
    handleListDelete?: () => void;
    item?: IListItem;
    list: IList;
}> = ({ onMenuItemClick, item, list, handleItemDelete, handleListDelete }) => {
    const [deleteListToo, setDeleteListToo] = useState(false);
    const [isList, setIsList] = useState(false);
    const [isLastItem, setIsLastItem] = useState(false);

    useEffect(() => {
        setIsList(item === undefined);

        if (!item) return;

        setIsLastItem(list.items.length === 1);
        setDeleteListToo(list.items.length === 1);
    }, [item]);

    const handleDelete = useCallback(() => {
        if (isList) {
            handleListDelete?.();
        } else {
            if (deleteListToo) {
                handleListDelete?.();
            } else {
                handleItemDelete?.();
            }
        }
    }, [isList, deleteListToo, handleItemDelete, handleListDelete]);

    return (
        <PoppedControl
            renderIcon={() => (
                <MenuItem onClick={() => onMenuItemClick?.()}>
                    <ListItemIcon>
                        <DeleteRounded />
                    </ListItemIcon>
                    <ListItemText>
                        {' '}
                        <Typography variant="body1" fontWeight="bold">
                            Smazat {!isList ? 'pozorování' : 'vycházku'}
                        </Typography>
                    </ListItemText>
                </MenuItem>
            )}
            renderControl={() => {
                return !isList && isLastItem ? (
                    <FormControlLabel
                        control={<Switch checked={deleteListToo} onChange={() => setDeleteListToo(!deleteListToo)} />}
                        label={
                            <Typography fontWeight={deleteListToo ? 600 : 100}>
                                Chci smazat i celou vycházku, protože se jedná o její jediné pozorování.
                            </Typography>
                        }
                        sx={{ pt: 2 }}
                    />
                ) : null;
            }}
            title={`Opravdu chcete smazat ${!isList ? 'pozorování' : 'vycházku'}?`}
            description={`Smazání ${
                !isList ? 'pozorování' : 'vycházky'
            } je nevratná akce. Jste si jisti, že jej chcete smazat?`}
            confirmButtonLabel={`Ano, chci ${
                isList ? 'vycházku' : deleteListToo ? 'pozorování i vycházku' : 'pozorování'
            } nevratně smazat`}
            cancelButtonLabel="Zpět"
            onConfirmed={handleDelete}
        />
    );
};

export default ItemDeleteDialog;
