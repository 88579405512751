import { Grid, SxProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { IListItem } from '../schemas/interfaces';
import { ListItemTags } from '../schemas/enums';
import DefinitionListItem from './DefinitionListItem';
import TaxonFromDict from './dicts/TaxonFromDict';
import SexFromDict from './dicts/SexFromDict';
import AgeFromDict from './dicts/AgeFromDict';
import ActivityFromDict from './dicts/ActivityFromDict';
import { useDict } from '../services/dictContext';

type Input = Pick<
    IListItem,
    | 'id'
    | 'taxonId'
    | 'sexId'
    | 'activityId'
    | 'ageId'
    | 'note'
    | 'count'
    | 'tags'
    | 'ringMark'
    | 'detectionId'
    | 'time'
>;

const ObsItemDefinitionList: FunctionComponent<{
    item: Input;
    hideColumns?: (keyof Input)[];
    sx?: SxProps;
    dense?: boolean;
}> = ({ item, hideColumns, sx, dense }) => {
    const { taxonId, count, sexId, activityId, ageId, note, tags, ringMark, detectionId } = item;
    const { detection: detections } = useDict();
    const hide = hideColumns || [];

    const detectionLabel = detections?.find((d) => d.id === detectionId)?.name?.cs;

    return (
        <Grid
            container
            component="dl"
            spacing={dense ? 0.5 : 1}
            sx={{
                '& div.MuiGrid-root:last-child': { display: 'none' },
                '& .MuiGrid-item': {
                    py: dense ? 0.5 : 1,
                    px: 1,
                },
                '& .MuiGrid-item:not(:last-of-type)': {
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                },
                marginBottom: 0,
                ...sx,
            }}
            className="ObsItemDefinitionList"
        >
            {!hide.includes('time') && !!item.time && <DefinitionListItem label="Čas" value={item.time} />}
            {!hide.includes('taxonId') && (
                <DefinitionListItem label="Druh" value={taxonId} formattedValue={<TaxonFromDict taxonId={taxonId} />} />
            )}
            {!hide.includes('count') && <DefinitionListItem label="Počet" value={count?.toString()} />}
            {!hide.includes('sexId') && (
                <DefinitionListItem label="Pohlaví" value={sexId} formattedValue={<SexFromDict sexId={sexId} />} />
            )}
            {!hide.includes('ageId') && (
                <DefinitionListItem label="Věk" value={ageId} formattedValue={<AgeFromDict ageId={ageId} />} />
            )}
            {!hide.includes('activityId') && (
                <DefinitionListItem
                    label="Aktivita"
                    value={activityId}
                    formattedValue={<ActivityFromDict activityId={activityId} />}
                />
            )}
            {!hide.includes('ringMark') && (tags?.includes(ListItemTags.ringMark) || !!ringMark) && (
                <DefinitionListItem label="Kroužek" value={ringMark} emptyValue={'kroužek bez\xa0dalších detailů'} />
            )}
            {!hide.includes('detectionId') && detectionLabel && (
                <DefinitionListItem label="Způsob detekce" value={detectionLabel} />
            )}
            {!hide.includes('note') && (
                <DefinitionListItem label="Poznámka" htmlValue={note?.replaceAll('\n', '<br />')} />
            )}
        </Grid>
    );
};

export default ObsItemDefinitionList;
