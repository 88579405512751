import { FC, useState } from 'react';
import { IMedia } from '../../schemas/interfaces';
import { Box, Button } from '@mui/material';
import MasonryImage from './MasonryImage';
import Fancybox from './Fancybox';

const PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS = 7;

const ObservationGallery: FC<{ media: IMedia[] }> = ({ media }) => {
    const [loadAllPhotos, setLoadAllPhotos] = useState(false);
    return (
        <>
            <Fancybox>
                {media.length > 0 && (
                    <a data-fancybox="gallery" href={media[0].url} key={media[0].id} data-caption={media[0].note}>
                        <MasonryImage
                            image={media[0]}
                            sx={{ maxHeight: '400px', width: '100%' }}
                            hideIcon
                            disableDialog
                        />
                    </a>
                )}
                {media.length > 1 && (
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: '1.125rem',
                            pt: 2.25,
                        }}
                    >
                        {media
                            .slice(1, loadAllPhotos ? undefined : PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS)
                            .map((image) => (
                                <a data-fancybox="gallery" href={image.url} key={image.id} data-caption={image.note}>
                                    <MasonryImage
                                        image={image}
                                        key={image.id}
                                        hideIcon
                                        disableDialog
                                        fixedAspectRatio
                                        sx={{
                                            maxHeight: '200px',
                                        }}
                                    />
                                </a>
                            ))}
                    </Box>
                )}
            </Fancybox>
            {!loadAllPhotos && media.length > PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS - 1 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    <Button size="small" onClick={() => setLoadAllPhotos(true)} variant="contained" sx={{ mt: 3 }}>
                        Zobrazit všechny fotografie
                    </Button>
                </Box>
            )}
        </>
    );
};

export default ObservationGallery;
